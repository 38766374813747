// App.js
import React from 'react';
import Projects from './components/Projects';

function App() {
  return (
    <Projects />
  );
}

export default App;
