// src/utils/languageStyles.js

const languageStyles = {
  CSS: {
    backgroundColor: '#563d7c', // Purple color for CSS
  },
  HTML: {
    backgroundColor: '#e34c26', // Orange color for HTML
  },
  JavaScript: {
    backgroundColor: '#f1e05a', // Yellow color for JavaScript
  },
  RichTextFormat: {
    backgroundColor: '#ccc', // Grey color for Rich Text Format (from the screenshot)
  },
  MATLAB: {
    backgroundColor: '#e16737', // Orange color for MATLAB (from the screenshot)
  },
  Python: {
    backgroundColor: '#3572A5', // Blue color for Python
  },
  CSharp: {
    backgroundColor: '#178600', // Green color for C#
  },
  Ruby: {
    backgroundColor: '#701516', // Red color for Ruby
  },
  Java: {
    backgroundColor: '#b07219', // Brown color for Java
  },
  PHP: {
    backgroundColor: '#4F5D95', // Blue-gray color for PHP
  },
  TypeScript: {
    backgroundColor: '#2b7489', // Blue color for TypeScript
  },
  Go: {
    backgroundColor: '#00ADD8', // Cyan color for Go
  },
  C: {
    backgroundColor: '#555555', // Gray color for C
  },
  CPlusPlus: {
    backgroundColor: '#f34b7d', // Pink color for C++
  },
  Swift: {
    backgroundColor: '#ffac45', // Orange color for Swift (based on GitHub's languages.yml)
  },
  Kotlin: {
    backgroundColor: '#A97BFF', // Purple color for Kotlin (based on GitHub's languages.yml)
  },
  R: {
    backgroundColor: '#198CE7', // Blue color for R
  },
  Shell: {
    backgroundColor: '#89e051', // Light green color for Shell
  },
  SQL: {
    backgroundColor: '#e38c00', // Orange color for SQL
  },
  Perl: {
    backgroundColor: '#0298c3', // Blue color for Perl
  },
  Dart: {
    backgroundColor: '#00B4AB', // Teal color for Dart
  },
  Haskell: {
    backgroundColor: '#5e5086', // Purple color for Haskell
  },
  Rust: {
    backgroundColor: '#dea584', // Light brown color for Rust
  },
  Lua: {
    backgroundColor: '#000080', // Navy blue color for Lua
  },
  Scala: {
    backgroundColor: '#c22d40', // Red color for Scala
  },
  Groovy: {
    backgroundColor: '#4298b8', // Blue color for Groovy
  },
  // Add more languages as needed
};

export const getLanguageStyle = (language) => {
  return languageStyles[language] || { backgroundColor: '#ccc' }; // Default grey for unknown languages
};